// Single "expertise"
.single-expertise {
  .expertise {
    &-filters {
      position: relative;
      z-index: 1;

      &__filters-items-item {
        @include media-breakpoint-up(mobile) {
          &:hover {
            > a {
              background-color: $red;
              color: $white !important;
            }
          }
        }

        &.active {
          > a {
            background-color: $red;
            color: $white !important;
          }
        }
      }
    }

    &-contents {
      &__filter-content {
        &-bg {
          z-index: -1;
        }

        &-text {
          strong {
            text-transform: uppercase;
          }
        }

        &-view-all-products {
          @include transition(background-color 600ms);

          > .icon {
            @include transition(transform 600ms);

            @include media-breakpoint-down(mobile) {
              @include toRem(width, 15);
              @include toRem(height, 15);

              svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          > span {
            @include transition(color 600ms);
          }

          @include media-breakpoint-up(mobile) {
            &:hover {
              background-color: $white;

              > span {
                color: $red !important;
              }

              > .icon {
                @include transform(rotate(45deg));

                svg path {
                  stroke: $red;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(mobile) {
    .model-product__item.mb-35 {
      margin-bottom: 0 !important;
    }
  }
}