// Template "Faq"
.page-template-faq {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  .header {
    @include media-breakpoint-up(lg) {
      &__image {
        @include toRem(height, 429);
      }
    }
  }

  .template-faq {
    &__items {
      .accordion-button {
        &:not(.collapsed) {
          > .icon {
            &.off {
              display: none !important;
            }

            &.on {
              display: block !important;
            }
          }
        }

        > .icon {
          @include toRem(width, 30);
          @include toRem(height, 30);

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .accordion-button {
      position: relative;
      width: 100%;
      text-align: left;
      background-image: url($icons + "more.svg");
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: center right;
      display: block;

      &:not(.collapsed) {
        background-image: url($icons + "less.svg");
      }
    }

    .nav-tabs {
      li {
        &.active {
          a {
            color: $red;
          }
        }
      }
    }
  }
}