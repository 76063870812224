//Model "news"
.model-news {
  &__item {
    $self: &;

    &-title {
      @include media-breakpoint-up(lg) {
        @include toRem(height, 40);
      }
    }

    &-short-text {
      @include media-breakpoint-up(xl) {
        @include toRem(height, 48);
      }
    }

    &-image {
      @include toRem(height, 300);

      @include media-breakpoint-up(mobile) {
        &:hover {
          > img {
            filter: blur(8px);
          }
        }
      }

      > span {
        position: relative;
        z-index: 2;
      }
    }

    &-bottom {
      @include media-breakpoint-up(mobile) {
        &:hover {
          &:before {
            @include transform(translateY(0));
          }

          .btn-link {
            z-index: 2;
            color: $white !important;

            > .icon {
              @include transform(rotate(45deg));

              svg path {
                stroke: $white;
              }
            }
          }

          #{ $self }-short-text {
            color: $black;
          }
        }
      }

      &:before {
        content: '';
        @include coverAbsolute;
        background-color: $red;
        z-index: 0;
        @include transform(translateY(101%));
        @include transition(transform 600ms);
      }
    }
  }
}