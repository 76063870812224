// Single "product"
.single-product {
  #site-navigations {
    margin-bottom: 0 !important;
  }
  
  &__results-item {
    @include media-breakpoint-up(md) {
      @include toRem(height, 280);

      &-image {
        @include toRem(width, 280);
      }
    }
  }

  &__the-benefits-image {
    position: relative;
    z-index: -1;
  }
}