// Template "Orphan Page"
.page-template-orphan-page {
  .header {
    @include media-breakpoint-up(lg) {
      &__bg {
        @include toRem(height, 630);
      }

      &__image {
        @include toRem(height, 370);
      }
    }
  }
}