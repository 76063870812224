//Animations
@include media-breakpoint-up(mobile) {
  [anim-fade] {
    opacity: 0;

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: all 2s ease #{$i * .1}s;
      }
    }

    &.is-active {
      opacity: 1;
    }
  }

  [anim-fade="in-top"] {
    @include transform(translateY(-30px));

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: all .6s ease #{$i * .05}s;
      }
    }

    &.is-active {
      @include transform(translateY(0));
    }
  }

  [anim-fade="in-bottom"] {
    @include transform(translateY(30px));

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: all .6s ease #{$i * .05}s;
      }
    }

    &.is-active {
      @include transform(translateY(0));
    }
  }
}