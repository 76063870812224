// Template "Contact"
.page-template-contact {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  .template-contact {
    &__form-objects {
      li {
        &.active {
          position: relative;

          .template-contact__form-objects-item {
            @include transform(translateX(0));
            color: $red !important;

            svg {
              path, line {
                stroke: $red;
              }
            }
          }
        }
      }

      &-item {
        @include transform(translateX(-45px));
        @include transition(transform 600ms);

        svg {
          path, line {
            @include transition(stroke 600ms);
          }
        }

        @include media-breakpoint-up(mobile) {
          &:hover {
            @include transform(translateX(0));
            color: $red !important;

            svg {
              path, line {
                stroke: $red;
              }
            }
          }
        }

        &.active {
          @include transform(translateX(0));
          color: $red !important;

          svg {
            path, line {
              stroke: $red;
            }
          }
        }
      }
    }

    &__form-item {
      &-default {
        position: relative;

        .mask {
          @include coverAbsolute;
          background-color: transparentize($white, .15);
          z-index: 2;
          pointer-events: none;
        }

        form {
          pointer-events: none;
        }
      }

      .row {
        @include toRem(margin-bottom, 15);

        @include media-breakpoint-up(lg) {
          @include toRem(margin-bottom, 30);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      label {
        width: 100%;
        position: relative;
        border-bottom: 1px solid $gray_4;
        color: $gray_6;

        &.no-border {
          border: 0;
        }

        > sup {
          position: relative;
          top: 0;
          @include toRem(margin-left, 1);
          @include toRem(font-size, 18);
        }
      }

      input, textarea {
        width: 100%;
        color: $gray_6;

        &::placeholder {
          color: $gray_4;
        }
      }

      select {
        width: 100%;
        color: $gray_4;
        @include toRem(padding-right, 12);
        background-image: url($icons + "arrow-down-gray.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 20px;
      }

      select, input {
        @include toRem(padding-top, 12);
        @include toRem(padding-bottom, 12);
      }

      textarea {
        @include toRem(padding, 12);
        @include toRem(margin-top, 12);
        @include toRem(margin-bottom, 12);
        border-color: $gray_4;
        border-radius: 0;

        &:focus {
          border-color: $black;
          border-radius: 0;
        }
      }
    }
  }
}