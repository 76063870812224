//Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 375px,
        md: 768px,
        lg: 992px,
        mobile: 1025px,
        xl: 1200px,
        laptop: 1280px,
        laptop2: 1440px,
        xxl: 1441px,
        specific: 1401px,
        big: 1600px
);

//Containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px,
        xxl: 1440px
);

//Spacers
$enable-negative-margins: true;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0: 0,
                        pixel15: 15px,
                        1: ($spacer*.25),
                        2: ($spacer*.4),
                        3: ($spacer*.8),
                        35: ($spacer*1.563),
                        4: ($spacer*1.875),
                        42: ($spacer*2.188),
                        45: ($spacer*2.5),
                        5: ($spacer*3),
                        55: ($spacer*3.5),
                        6: ($spacer*3.85),
                        65: ($spacer*5),
                        68: ($spacer*5.5),
                        7: ($spacer*6.25),
                        75: ($spacer*7),
                        8: ($spacer*8),
                        9: ($spacer*10),
                        95: ($spacer*12),
                        10: ($spacer*14),
                        11: ($spacer*21),
                        12: ($spacer*16),
                        13: ($spacer*17),
                        14: ($spacer*18),
                        15: ($spacer*19),
                        16: ($spacer*20),
                        17: ($spacer*21),
                        18: ($spacer*22),
                        19: ($spacer*23),
                        20: ($spacer*24),
                        21: ($spacer*25),
                        22: ($spacer*26),
                        23: ($spacer*27),
                        24: ($spacer*28),
                        25: ($spacer*29),
                        26: ($spacer*30),
                        27: ($spacer*31),
                        28: ($spacer*32),
                        29: ($spacer*33),
                        30: ($spacer*34),
                        100: ($spacer*6.25),
                ),
                $spacers
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

//Font-sizes
$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 3.9375rem,
        4: 3.4375rem,
        5: 3rem,
        6: 2.5rem,
        7: 2rem,
        8: 1.5625rem,
        9: 1.35rem,
        10: 0.75rem,
);

//Position
$position-values: (
        0: 0,
        20: 20%,
        50: 50%,
        100: 100%
);

//Gutters
$grid-gutter-width: 1.5rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
        8: $spacer * 6,
        9: $spacer * 7,
        10: $spacer * 8,
);

//Width
$utilities: (
        "width": (
                property: width,
                class: w,
                values: (
                        25: 25%,
                        35: 35%,
                        40: 40%,
                        50: 50%,
                        55: 55%,
                        60: 60%,
                        65: 65%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        120: 120%,
                        auto: auto
                )
        ),
        "height": (
                property: height,
                class: h,
                values: (
                        25: 25%,
                        35: 35%,
                        40: 40%,
                        45: 45%,
                        50: 50%,
                        60: 60%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        auto: auto
                )
        ),
        "text-opacity": (
                css-var: true,
                class: text-opacity,
                values: (
                        25: .25,
                        50: .5,
                        75: .75,
                        80: .8,
                        100: 1
                )
        )
);

//Line-height
$line-height-base: 1.65;
$line-height-sm: 1.208;
$line-height-lg: 1.65;

//Buttons
.nav-link, a {
  color: $black;
  text-decoration: none;
  @include transition(all 600ms);
}

.load-more {
  &:hover {
    background-color: $red !important;
    color: $white !important;
  }
}

.btn-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $black;
  font-weight: 700;
  outline: 0;
  text-align: left;
  padding: 0;
  text-decoration: none;

  > .icon {
    @include toRem(width, 15);
    @include toRem(height, 15);
    @include transition(transform 600ms);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        @include transition(stroke 600ms);
      }
    }
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      color: $red !important;

      > .icon {
        @include transform(rotate(45deg));
      }
    }
  }

  &-icon-red {
    > .icon {

      svg path {
        stroke: $red;
      }
    }
  }
}

.btn-underline {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $black;
  text-align: left;
  outline: 0;
  padding: 0;
  text-decoration: none;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    transform-origin: right;
    background-color: $dark;
    transition: transform 600ms;
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      color: $red !important;

      &:before {
        transform: scaleX(0);
      }
    }
  }
}

.btn-primary {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-color: $white;
  text-align: left;
  @include toRem(border-radius, 3);
  @include toRem(padding-left, 40);
  @include toRem(padding-right, 40);
  @include toRem(height, 70);
  @include transition(background-color 600ms, border-color 600ms);

  @include media-breakpoint-down(md) {
    width: 100%;
    height: auto;
  }

  &-green {
    border-color: $green;
    color: $green;

    > svg {
      path {
        stroke: $green;
        @include transition(all 600ms);
      }
    }
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      background-color: $red;
      border-color: $red;
      color: $white;

      > img,
      > svg {
        @include transform(translateX(10px));
      }

      > svg {
        path {
          stroke: $white;
        }
      }
    }
  }

  > img,
  > svg {
    display: inline-flex;
    @include transition(transform 600ms);

    @include media-breakpoint-up(md) {
      @include toRem(margin-left, 130);
    }
  }
}

.btn-big {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  line-height: 2.15rem;
  @include toRem(border-radius, 45);
  color: $white;
  font-weight: 700;
  @include toRem(padding-top, 20);
  @include toRem(padding-bottom, 20);
  @include toRem(padding-left, 25);
  @include toRem(padding-right, 25);
  @include toRem(height, 50);
  @include toRem(font-size, 12);
  @include toRem(line-height, 22);
  @include transition(all 600ms);

  @include media-breakpoint-up(xxl) {
    @include toRem(height, 105);
  }

  @include media-breakpoint-up(lg) {
    @include toRem(font-size, 20);
    @include toRem(height, 90);
  }

  @include media-breakpoint-up(xl) {
    @include toRem(min-width, 595);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  &-contact {
    height: auto;
    @include toRem(padding-top, 12);
    @include toRem(padding-bottom, 12);
    @include toRem(padding-left, 15);
    @include toRem(padding-right, 15);
    @include toRem(font-size, 18);
    @include toRem(min-width, 185);

    &-bis {
      @include toRem(padding-top, 16);
      @include toRem(padding-bottom, 16);
    }
  }

  &-height {
    @include toRem(height, 175);
  }

  .icon {
    @include toRem(margin-left, 25);
    @include transition(transform 600ms);

    @include media-breakpoint-down(lg) {
      @include toRem(width, 15);
      position: relative !important;
      margin: 0 !important;
    }

    svg {
      path {
        @include transition(stroke 600ms);
      }
    }
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      background-color: $white;
      border-color: $gray_6 !important;
      color: $gray_6;

      .icon {
        @include transform(rotate(45deg));

        svg path {
          stroke: $gray_6;
        }
      }

      .icon-close {
        > svg {
          path {
            fill: $gray_6;
          }
        }
      }
    }
  }

  .icon-close {
    @include toRem(width, 20);
    @include toRem(height, 20);

    > svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        fill: $gray_4;
        @include transition(fill 600ms);
      }
    }
  }

  &-mw-auto {
    min-width: auto !important;
  }

  &-xs {
    @include media-breakpoint-up(lg) {
      @include toRem(min-width, 438);
    }
  }

  &-gray {
    background-color: $gray_6;
    border: 1px solid $gray_6;
  }

  &-red {
    background-color: $red;
    border: 1px solid $red;
  }

  &-border {
    border: 1px solid $gray_6;
    color: $gray_6;

    @include media-breakpoint-up(mobile) {
      &:hover {
        background-color: $red;
        border-color: $red;
        color: $white !important;

        > .icon {
          @include transform(rotate(45deg));

          svg path {
            stroke: $white;
          }
        }
      }
    }

    > .icon {
      svg path {
        stroke: $gray_6;
      }
    }
  }

  &-white {
    background-color: $white;
    border: 1px solid $white;
    color: $black;

    @include media-breakpoint-up(mobile) {
      &:hover {
        background-color: $red;
        border-color: $red;
        color: $white !important;

        > .icon {
          svg path {
            stroke: $white;
          }
        }
      }
    }

    > .icon {
      svg path {
        stroke: $red;
      }
    }
  }
}