// Navigation
:root {
  --height-site-navigations: 180px;
  --height-site-navigations-sticky: 110px;

  @include media-breakpoint-up(mobile) {
    --height-site-navigations-sticky: 75px;
  }
}

.h-nav {
  @include media-breakpoint-down(mobile) {
    height: 77px !important;
  }
}

#site-navigations {
  position: relative;
  z-index: 1000;
  width: 100%;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);

  @include media-breakpoint-down(mobile) {
    @include toRem(height, 93);
    position: fixed !important;
    width: 100%;
    left: 0;
    top: 0;
    transition: ease 0.4s;
  }

  .navigation-top__top-left {
    $self: &;

    &-col {
      @include media-breakpoint-down(laptop) {
        position: relative !important;
        justify-content: flex-start !important;
        top: 3px;
      }
    }

    .menu-item {
      &.submenu-our-expertise {
        @include media-breakpoint-up(mobile) {
          &:hover {
            aside#navigation-top__submenu-1 {
              @include transform(translateY(0));
              opacity: 1 !important;
              pointer-events: auto !important;

              &:hover {
                @include transform(translateY(0));
                opacity: 1 !important;
                pointer-events: auto !important;
              }
            }
          }
        }
      }

      &.submenu-about-us {
        @include media-breakpoint-up(mobile) {
          &:hover {
            aside#navigation-top__submenu-2 {
              @include transform(translateY(0));
              opacity: 1 !important;
              pointer-events: auto !important;

              &:hover {
                @include transform(translateY(0));
                opacity: 1 !important;
                pointer-events: auto !important;
              }
            }
          }
        }
      }
    }

    &__submenu {
      top: var(--height-site-navigations);
    }
  }
}

.navigation-top {
  $self: &;

  &__col-logo {
    @include media-breakpoint-between(mobile, specific) {
      position: absolute;
      @include toRem(top, 110);
      width: 50%;
    }
  }

  &__logo {
    z-index: 1;

    > svg {
      @include media-breakpoint-down(mobile) {
        @include toRem(width, 80);
      }
    }
  }

  &__search-form {
    input[name="s"] {
      &::placeholder {
        color: $gray_4;
      }
    }
  }


  &__top-left {
    .menu-item {
      > .item-link {
        cursor: pointer;
        @include transition(color 600ms);

        @include media-breakpoint-up(mobile) {
          &:hover {
            color: $red;
          }
        }
      }

      &.current-menu-item,
      &.current_page_item,
      &.current-menu-ancestor,
      &.current-menu-parent {
        > .item-link {
          color: $red;
        }
      }

      > .icon {
        position: relative;
        @include toRem(top, -1);
      }
    }
  }

  &__languages-wrapper {
    position: relative;
    z-index: 100;
  }

  &__top-right {
    .menu-item {
      @include transition(border-color 600ms);

      &.red {
        > .item-link {
          background-color: $red;
          color: $white;
        }
      }

      > .item-link {
        position: relative;
        overflow: hidden;
        z-index: 0;
        @include transition(all 600ms);

        svg {
          path {
            @include transition(stroke 225ms);
          }
        }

        @include media-breakpoint-up(mobile) {
          &:hover {
            background-color: $gray_6;
            color: $white !important;

            svg {
              path {
                stroke: $white;
              }
            }

            &:before {
              @include transform(scaleX(1));
            }
          }
        }
      }
    }
  }

  &__top-right-2 {
    .menu-item {

      &.current-menu-item,
      &.current_page_item,
      &.current-menu-ancestor,
      &.current-menu-parent {
        > a {
          color: $red;

          .page-template-contact & {
            color: $white;
          }
        }
      }
    }
  }

  &__submenu-title-rotate {
    @include transform(rotate(-90deg));
    right: calc(100% - 55px);
    transform-origin: right;
    @include toRem(top, -10);
    text-align: right;
  }

  &__submenu-1-item-menu {
    .menu-image {
      @include toRem(width, 105);
      @include toRem(height, 105);
    }

    .item-link {
      @include media-breakpoint-up(mobile) {
        &:hover {
          .icon {

            &:before {
              @include transform(scaleX(1));
            }

            svg path {
              stroke: $white;
            }
          }
        }
      }

      .icon {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          transform-origin: right;
          @include transform(scaleX(0));
          z-index: -1;
          background-color: $red;
          @include transition(transform 600ms);
        }

        svg path {
          @include transition(stroke 600ms);
        }
      }
    }
  }

  &__submenu {
    @include transform(translateY(15px));
    @include transition(transform 600ms, opacity 225ms);
    z-index: 1000;

    @include media-breakpoint-up(mobile) {
      &:hover {
        @include transform(translateY(0));
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    }

    &.active {
      @include transform(translateY(0));
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }

  &__submenu-2-item-menu,
  &__submenu-3-item-menu,
  &__submenu-4-item-menu {
    .item-link {
      @include media-breakpoint-up(mobile) {
        &:hover {
          .icon {
            @include transform(translateX(80%));

            svg path {
              stroke: $red;
            }
          }
        }
      }

      .icon {
        @include transition(transform 600ms);

        svg path {
          @include transition(stroke 600ms);
        }
      }
    }
  }

  &__submenu-3-item-menu {
    .menu-image {
      @include toRem(height, 160);

      @include media-breakpoint-up(xxl) {
        @include toRem(height, 220);
      }

      img {
        object-position: top;
      }
    }
  }
}

//Burger Menu
#burger-menu {
  position: relative;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  @include transition(all 600ms ease-in-out);
  cursor: pointer;

  > .burger-menu-wrapper {
    @include toRem(width, 20);
    @include toRem(height, 20);
  }

  &.open span {
    &:nth-child(1) {
      @include toRem(top, 10);
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      @include transform(rotate(45deg));
      @include toRem(top, 9);
    }

    &:nth-child(3) {
      @include transform(rotate(-45deg));
      @include toRem(top, 9);
    }
  }

  span {
    display: block;
    position: absolute;
    @include toRem(height, 2);
    width: 100%;
    border-radius: 2px;
    background-color: $black;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    transition: .25s ease-in-out;

    &:nth-child(1) {
      @include toRem(top, 5);
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include toRem(top, 13);
    }
  }
}

//Search Menu
#search-menu {
  -webkit-transform: translate3d(0, 0, 0);

  svg {
    @include toRem(width, 18);
    @include toRem(height, 18);
    object-fit: contain;
  }

  &.open {
    > div > svg {
      display: none !important;
    }

    #close-search-menu {
      display: flex !important;
    }
  }
}

#site-navigations__search {
  z-index: 5;
  opacity: 0;
  @include toRem(top, 110);
  @include toRem(height, 75);
  @include transform(translateY(-100%));
  @include transition(transform 600ms cubic-bezier(.42, 0, .58, 1));

  &.open {
    opacity: 1;
    pointer-events: auto !important;
    @include transform(translateX(0));
  }
}

#site-navigations__mobile {
  z-index: 4;
  @include transform(translateX(-100%));
  @include transition(transform 600ms cubic-bezier(.42, 0, .58, 1));

  &.open {
    pointer-events: auto !important;
    @include transform(translateX(0));
  }

  > .container-fluid {
    padding-top: 82px;
    padding-bottom: 82px;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .navigation-top {
      &__submenu {
        @include toRem(top, 75);
        @include transform(translateX(-100%));
        z-index: 7;

        &.active {
          pointer-events: auto !important;
          @include transform(translateX(0));
          opacity: 1 !important;
        }

        > .container-fluid {
          overflow-x: hidden;
          overflow-y: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      &__search-form {
        z-index: 8;
        width: 100%;
        top: 84px !important;
        position: fixed !important;
        left: 0;
        padding: 10px 27px !important;
      }

      &__back-submenu {
        > span:last-child {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            background-color: $black;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.list__lang {
  cursor: pointer;

  > .item__lang {
    position: relative;
    overflow: hidden;
    z-index: 0;
    @include transition(all 225ms, background-color 600ms);

    > span {
      @include toRem(padding-right, 12);
      background-image: url($icons + "arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 8px;
    }
  }

  &:hover {
    > .item__lang {
      @include media-breakpoint-up(mobile) {
        background-color: $gray_6;
        color: $white;

        &:before {
          @include transform(scaleX(1));
        }

        > span {
          background-image: url($icons + "arrow-down-white.svg");
        }
      }
    }

    .list__lang-drop {
      opacity: 1 !important;
      pointer-events: auto !important;

      @include media-breakpoint-down(mobile) {
        width: 80% !important;
        left: 10% !important;
        height: 40px;
        z-index: 1;
      }

      .simple-link {
        &:hover {
          color: $black !important;
        }
      }
    }
  }
}

.btn-contact-nav-trigger .btn-contact-nav {
  display: inline-flex;
  align-items: center;
  @include toRem(padding-left, 20);
  @include toRem(padding-right, 20);
  @include toRem(min-width, 280);
  @include toRem(height, 50);
  @include toRem(border-radius, 4);
  background-color: $red;
  color: $white;
  @include transition(all 600ms);

  @include media-breakpoint-up(mobile) {
    &:hover {
      background-color: $gray_6;
    }
  }
}

#site-navigations-sticky {
  z-index: 6;
  @include transform(translateY(0));
  height: var(--height-site-navigations-sticky);
  @include transition(transform 600ms cubic-bezier(.42, 0, .58, 1));

  @include media-breakpoint-up(mobile) {
    @include transform(translateY(-100%));
  }

  &.scroll-up {
    pointer-events: none !important;
    @include transform(translateY(-100%));
  }

  &.scroll-down {
    pointer-events: auto !important;
    @include transform(translateY(0));
  }

  .navigation-top-sticky {
    $self: &;

    .menu-item {
      &.submenu-our-expertise {
        @include media-breakpoint-up(mobile) {
          &:hover {
            aside#navigation-top__submenu-1 {
              @include transform(translateY(0));
              opacity: 1 !important;
              pointer-events: auto !important;

              &:hover {
                @include transform(translateY(0));
                opacity: 1 !important;
                pointer-events: auto !important;
              }
            }
          }
        }
      }

      &.submenu-about-us {
        @include media-breakpoint-up(mobile) {
          &:hover {
            aside#navigation-top__submenu-2 {
              @include transform(translateY(0));
              opacity: 1 !important;
              pointer-events: auto !important;

              &:hover {
                @include transform(translateY(0));
                opacity: 1 !important;
                pointer-events: auto !important;
              }
            }
          }
        }
      }
    }

    &.navigation-top__logo {
      > svg {
        @include toRem(width, 80);
      }
    }

    .navigation-top__submenu {
      top: var(--height-site-navigations-sticky);
    }
  }
}

#search-form-col {
  @include toRem(width, 300);
}