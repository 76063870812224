// Single "application"
.single-application {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  &__timeline {
    @include media-breakpoint-up(mobile) {
      @include toRem(min-height, 950);
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(min-height, 1225);
    }

    @include media-breakpoint-up(big) {
      @include toRem(min-height, 950);
    }

    &-bg {
      z-index: -1;
    }

    &-title {
      @include media-breakpoint-down(xl) {
        color: $black !important;
      }
    }

    &-items-item {
      &-text {
        @include media-breakpoint-down(lg) {
          padding: 0 20px;
        }
      }

      &-line {
        position: absolute;
        top: 55px;
        @include toRem(left, 14);
        @include toRem(width, 1);
        background-color: $red;
        height: inherit;
      }

      &-counter {
        position: relative;
        line-height: normal;
      }
    }
  }

  &__benefits-of-activa-fv-item-image {
    @include toRem(height, 245);
  }

  &__results-item {
    @include media-breakpoint-up(lg) {
      @include toRem(height, 280);

      &-image {
        @include toRem(width, 280);
      }
    }
  }
}