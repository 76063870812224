// Homepage
.block-home {
  &-about-us {
    &__boxes-item {
      @include toRem(width, 104);
      @include toRem(height, 104);

      @include media-breakpoint-up(md) {
        @include toRem(width, 104);
        @include toRem(height, 104);
      }

      @include media-breakpoint-up(lg) {
        @include toRem(width, 240);
        @include toRem(height, 240);
      }

      @include media-breakpoint-up(xl) {
        @include toRem(width, 220);
        @include toRem(height, 220);
      }

      @include media-breakpoint-up(laptop2) {
        @include toRem(width, 240);
        @include toRem(height, 240);
      }

      &-text {
        @include media-breakpoint-down(lg) {
          @include toRem(font-size, 8);
          @include toRem(line-height, 10);
        }
      }

      &-icon {
        @include media-breakpoint-down(lg) {
          @include toRem(width, 32);
          @include toRem(height, 32);
        }
      }
    }

    &__image {
      z-index: -1;
      opacity: .2;
      @include toRem(width, 434);
      @include toRem(height, 598);

      @include media-breakpoint-up(md) {
        opacity: 1;
      }
    }
  }

  &-our-expertise {
    &__expertise-item {
      @include media-breakpoint-down(lg) {
        border-left: 1px solid $gray_4;
      }

      &:last-child {
        border-right: 0 !important;
      }

      @include media-breakpoint-up(mobile) {
        &:hover {
          .block-home-our-expertise__expertise-item-image img {
            filter: blur(12px);
          }
        }
      }

      &-filters-item {
        @include media-breakpoint-down(mobile) {
          @include toRem(font-size, 14);
        }
      }

      &-image {
        @include toRem(height, 250);
      }

      &-title {
        @include toRem(height, 60);

        @include media-breakpoint-up(mobile) {
          @include toRem(height, 100);
        }

        @include media-breakpoint-down(mobile) {
          @include toRem(font-size, 14);
        }

        > .icon {
          @include transition(transform 600ms);

          @include media-breakpoint-down(mobile) {
            @include toRem(width, 38);
          }
        }

        @include media-breakpoint-up(mobile) {
          &:hover {
            > .icon {
              @include transform(rotate(45deg));
            }
          }
        }
      }
    }
  }

  &-our-products {
    &__deco-image {
      z-index: -1;

      &#deco-image-1 {
        @include toRem(width, 220);
        @include toRem(height, 150);
      }

      &#deco-image-2 {
        @include toRem(width, 333);
        @include toRem(height, 355);
      }
    }
  }

  &-shop {
    &__col {
      &-left {
        position: relative;
        z-index: 1;
        @include toRem(margin-top, -280);

        @include media-breakpoint-up(xl) {
          @include toRem(margin-right, -330);
          margin-top: 0;
        }
      }

      &-right {
        position: relative;
        z-index: 0;
      }
    }

    &__image {
      position: relative;

      @include media-breakpoint-up(xl) {
        @include toRem(top, -150);
      }
    }

    &__image-2 {
      @include toRem(width, 414);
      @include toRem(height, 418);
      @include toRem(margin-right, -150);
    }

    .mobile-h-auto {
      height: auto !important;
    }
  }

  &-extranet {
    &__bg-image {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: transparentize($black, .75);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px);
      }

      > img {
        z-index: -2;
      }
    }

    &__items-item-icon {
      justify-content: center;
      @include toRem(width, 70);
      @include toRem(height, 70);
    }

    &__boxes {
      @include media-breakpoint-up(lg) {
        @include toRem(height, 370);
      }

      > div {
        @include media-breakpoint-up(lg) {
          padding-right: calc(var(--bs-gutter-x) * 1);
          padding-left: calc(var(--bs-gutter-x) * 1);
        }
      }
    }

    &__title {
      @include media-breakpoint-down(mobile) {
        color: $black !important;
      }
    }

    &__link {
      min-width: auto !important;

      @include media-breakpoint-down(mobile) {
        height: 50px !important;
      }
    }
  }

  &-map {
    &__map {
      z-index: -1;

      &-content {
        @include media-breakpoint-down(md) {
          position: relative !important;
        }
      }
    }
  }
}