.slider_title_text_image {
  .tns-nav {
    display: none;
  }

  &__images-item {
    @include media-breakpoint-up(lg) {
      @include toRem(height, 470);
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(height, 600);
    }
  }

  &__rich-text {
    > p:last-child {
      margin-bottom: 0 !important;
    }
  }
}