.css-pagination{
  display: flex ;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
  margin: auto;
  .pager__page{
    color: $black;
    transition: 0.2s linear;
    //border : $gray_1 solid 1px;
    //border-radius: 100%;
    padding: 4px;
    &:hover{
      color: $gray_4 !important;
      //background-color: $gray_4 ;
    }
  }
  .pager__current{
    color: $red;
    font-weight: bold;
   // border : $red solid 1px;
   // border-radius: 100%;
    padding: 4px;
  }
}