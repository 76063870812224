body {
  min-width: 320px;
  background-color: $white;
  color: $black;
  @include toRem(font-size, 14);
  @include toRem(line-height, 20);
  font-family: 'Helvetica', sans-serif;
  @include antialiasing();
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(mobile) {
    @include toRem(font-size, 18);
    @include toRem(line-height, 24);
  }

  &.open-menu,
  &.open-menu-burger,
  &.open-menu-search {
    //position: fixed;
    width: 100%;
    overflow: hidden;
  }
}

strong {
  font-weight: 700;
}

img {
  @include transition(filter 600ms);
}

input,
select,
button {
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
  outline: 0;
}

.main {
  position: relative;
  z-index: 1;
}

#app,
.site-container {
  overflow-x: hidden;
}

.pin-spacer {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

@include media-breakpoint-down(mobile) {
  .site-container {
    padding-top: 140px;

    body.single-post &,
    body.single-application &,
    body.single-product &,
    body.page-template-our-enterprise &,
    body.page-template-contact &,
    body.page-template-faq &,
    body.page-template-our-applications &,
    body.page-template-sustainability &,
    body.tax-type_of_applications &,
    body.page-template-careers & {
      padding-top: 110px;
    }
  }
}

.container-fluid {
  @include toRem(padding-left, 12);
  @include toRem(padding-right, 12);

  @include media-breakpoint-up(mobile) {
    @include toRem(padding-left, 30);
    @include toRem(padding-right, 30);
  }
}

a:not(.btn) {
  @include media-breakpoint-up(mobile) {
    &:hover {
      color: $red !important;
    }
  }
}

.border {
  &-gray-3 {
    --bs-border-color: #D7D7D7;
  }

  &-gray-4 {
    --bs-border-color: #C2C2C2;
  }

  &-red {
    --bs-border-color: #EE1C26;
  }

  &-gray-custom {
    --bs-border-color: #707070;
  }
}

.text {
  &-red {
    color: $red;
  }

  &-gray-1 {
    color: $gray_1;
  }

  &-gray-2 {
    color: $gray_2;
  }

  &-gray-5 {
    color: $gray_5;
  }
}

.icon-svg {
  svg path {
    @include transition(fill 225ms);
  }

  @include media-breakpoint-up(mobile) {
    &:hover svg path {
      fill: $red;
    }
  }
}

.anim-scale-img {
  overflow: hidden;

  img {
    @include transition(all 350ms);
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      img {
        @include transform(scale(1.15));
      }
    }
  }
}

.card {
  --bs-card-border-radius: 0;
}

.bg {
  &-red {
    background-color: $red;
  }

  &-gray-6 {
    background-color: $gray_6;
  }

  &-gray-7 {
    background-color: $gray_7;
  }

  &-blur {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);

    &-2 {
      background: rgba(62, 58, 58, 0.4);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
    }
  }
}

.field {
  &__title {
    strong {
      font-weight: 800;
    }
  }

  &__text {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        @include toRem(padding-left, 8);

        &:before {
          content: '';
          position: absolute;
          left: 0;
          @include toRem(top, 16);
          @include toRem(width, 2);
          @include toRem(height, 2);
          background-color: $black;
          border-radius: 50%;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .column-count-3 {
    column-count: 3;
  }
}

.fs {
  &-9 {
    @include toRem(font-size, 9);
  }

  &-10 {
    @include toRem(font-size, 10);
  }

  &-11 {
    @include toRem(font-size, 11);
  }

  &-12 {
    @include toRem(font-size, 10);

    @include media-breakpoint-up(xxl) {
      @include toRem(font-size, 12);
    }
  }

  &-13 {
    @include toRem(font-size, 11);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 13);
    }
  }

  &-14 {
    @include toRem(font-size, 14);

    @include media-breakpoint-up(xxl) {
      @include toRem(font-size, 14);
    }
  }

  &-15 {
    @include toRem(font-size, 15);
  }

  &-16 {
    @include toRem(font-size, 12);

    @include media-breakpoint-up(laptop) {
      @include toRem(font-size, 14);
    }

    @include media-breakpoint-up(laptop2) {
      @include toRem(font-size, 16);
    }
  }

  &-18 {
    @include toRem(font-size, 16);

    @include media-breakpoint-up(mobile) {
      @include toRem(font-size, 18);
    }
  }

  &-20 {
    @include toRem(font-size, 20);
  }

  &-25 {
    @include toRem(font-size, 16);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 25);
    }
  }

  &-32 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(mobile) {
      @include toRem(font-size, 32);
    }
  }

  &-35 {
    @include toRem(font-size, 30);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 35);
    }
  }

  &-36 {
    @include toRem(font-size, 18);

    @include media-breakpoint-up(mobile) {
      @include toRem(font-size, 36);
    }
  }

  &-40 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 35);
    }

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 40);
    }
  }

  &-42 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 35);
    }

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 42);
    }
  }

  &-45 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 38);
    }

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 45);
    }
  }

  &-48 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 28);
    }

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 48);
    }
  }

  &-50 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 32);
    }

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 50);
    }
  }

  &-100 {
    @include toRem(font-size, 60);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 100);
    }
  }
}

@include media-breakpoint-up(md) {
  .column-count-2 {
    column-count: 2;
  }
}

.values__items-item-icon {
  @include toRem(width, 50);
  @include toRem(height, 50);
}

.plyr {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;

  .plyr__control--overlaid {
    background: $white;
    color: var(--plyr-video-control-color, $black);
    opacity: 1;
    padding: calc(var(--plyr-control-spacing, 30px) * 1);

    @include media-breakpoint-up(lg) {
      padding: calc(var(--plyr-control-spacing, 30px) * 1.5);
    }

    @include media-breakpoint-up(mobile) {
      &:hover {
        background: $red !important;
      }
    }

    svg {
      height: var(--plyr-control-icon-size, 25px);
      width: var(--plyr-control-icon-size, 25px);

      @include media-breakpoint-up(lg) {
        height: var(--plyr-control-icon-size, 40px);
        width: var(--plyr-control-icon-size, 40px);
      }
    }
  }

  .plyr__video-wrapper {
    position: relative;
    height: 100%;

    .plyr__poster {
      background-size: cover;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.fw {
  &-italic {
    font-style: italic;
  }

  &-regular {
    font-weight: 400;
  }

  &-extra-bold {
    font-weight: 800;
  }
}

.tab-pane {
  display: none !important;

  &.active {
    display: block !important;
  }
}

.gm-style {
  .gm-style-iw-c {
    border-radius: 0 !important;
    padding: 20px !important;
    outline: 0 !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-c {
    background-color: $red !important;
  }

  .gm-style-iw-tc::after {
    content: none !important;
  }
}

.marker-position {
  bottom: 5px;
  left: 0;
  position: relative;
  font-weight: 700;
}

.shadow-custom {
  box-shadow: 0 3px 6px transparentize($black, .84);
}

.slider-button {
  padding: 0;
  @include toRem(width, 40);
  @include toRem(height, 40);

  @include media-breakpoint-up(mobile) {
    @include toRem(width, 60);
    @include toRem(height, 60);
  }

  &.slider-prev {
    @include toRem(margin-right, 4);

    @include media-breakpoint-up(mobile) {
      @include toRem(margin-right, 8);
    }
  }

  &-red {
    svg {
      circle {
        fill: $red;
      }

      path {
        stroke: $white;
      }
    }

    @include media-breakpoint-up(mobile) {
      &:hover {
        svg {
          circle {
            fill: $white !important;
            stroke: $white !important;
          }

          path {
            stroke: $red !important;
          }
        }
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;

    circle,
    path {
      @include transition(all 125ms);
    }
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      svg {
        circle {
          fill: $red;
        }

        path {
          stroke: $white;
        }
      }
    }
  }
}

.min-w-auto {
  min-width: auto !important;
}

.gm-style-cc {
  display: none;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

//Certificates
.certificates {
  &__items-item {
    flex: 1 0 0;

    img {
      @include toRem(width, 150);
      @include toRem(height, 150);
    }

    @include media-breakpoint-down(lg) {
      flex: 0 0 auto;

      img {
        height: auto;
        width: 100%;
        max-height: 80px;
      }
    }
  }
}

.search {
  position: relative;

  &-type {
    text-transform: uppercase;
  }
}

.searchwp-highlight {
  background-color: $red;
  color: $white;
  font-weight: 700;
}

.block-title {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 90px;

  &:after {
    content: '';
    width: 60px;
    height: 2px;
    background: $gray_6;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &-number {
    display: block;
    font-size: 250px;
    line-height: normal;
    color: $gray_7;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  &-line-1 {
    display: block;
    font-size: 16px;
    line-height: normal;
    color: $gray_6;
  }

  &-line-2 {
    display: block;
    font-weight: 700;
    font-size: 36px;
  }

  &.block-title-right {
    text-align: right;

    &:after {
      left: auto;
      right: 0;
      transform: none;
    }

    .block-title-number {
      left: auto;
      right: -6px;
      transform: translate(0, -45%);
    }
  }

  &.block-title-left {
    text-align: left;

    &:after {
      left: 0;
      transform: none;
    }

    .block-title-number {
      left: -6px;
      transform: translate(0, -45%);
    }
  }
}

mark, .mark {
  background-color: transparent;
}

.select-model {
  width: 100%;
  background-color: $red;
  color: $white;
  font-weight: 700;
  background-image: url($icons + "arrow-down-white.svg");
  background-repeat: no-repeat;
  background-position: right 14px top 13px;
  background-size: 18px;
  border-radius: 6px;

  @include media-breakpoint-down(mobile) {
    background-position: right 30px top 13px;
  }
}

.accordion-arrow-down {
  background-image: url($icons + "arrow-down.svg") !important;
  background-repeat: no-repeat;
  background-position: right 14px top 13px !important;
  background-size: 18px !important;
  border-radius: 6px;

  &:not(.collapsed) {
    background-image: url($icons + "arrow-up.svg") !important;
  }
}

.seo {
  &__seo-image {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      @include toRem(left, -30);
      @include toRem(top, -30);
      width: calc(100% - 60px);
      height: calc(100% + 150px);
      background-color: $gray_7;
      pointer-events: none;
    }
  }

  &__seo-text {
    a {
      text-decoration: underline;
    }
  }
}

.our-mission__our-mission-item-image {
  @include toRem(height, 245);
}

.js-slider-collaborators .tns-transparent {
  opacity: 1 !important;
  visibility: visible !important;
}

.js-slider-collaborators__slider-nav .slider-button {
  display: flex !important;
}

.reset-form {
  display: none;
}

.field {
  &-text {
    > p {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &-rich-text {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}