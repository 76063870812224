// Template "Text"
.page-template-text {
  .template-text {
    &__rich-text {
      h2 {
        color: $red;
        font-weight: 700;
        text-transform: uppercase;
        @include toRem(font-size, 18);
        @include toRem(margin-bottom, 25);
      }
    }
  }
}