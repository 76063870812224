// Page 404
#page-404 {
  .block-title {
    position: relative;
    text-align: center;
    text-transform: uppercase;

    .block-title-number {
      display: block;
      font-size: 250px;
      line-height: normal;
      color: $gray_7;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -45%));
    }

    .block-title-line-1 {
      display: block;
      font-size: 16px;
      line-height: normal;
      font-weight: 100;
      color: #666;
    }

    .block-title-line-2 {
      display: block;
      font-weight: 700;
      font-size: 36px;
    }
  }
}