//Model "product"
.model-product {
  $self: &;

  @include media-breakpoint-up(mobile) {
    &:hover {
      #{ $self }__image > img {
        filter: blur(8px);
      }
    }
  }

  &__image {
    @include media-breakpoint-up(md) {
      @include toRem(height, 200);
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(height, 308);
    }

    > span {
      position: relative;
      z-index: 2;
    }
  }

  &__filters {
    &-item {
      @include toRem(font-size, 12);

      @include media-breakpoint-down(mobile) {
        @include toRem(font-size, 10);
      }
    }

    a {
      @include transition(all 600ms);

      @include media-breakpoint-up(mobile) {
        &:not(.btn):hover {
          background-color: $gray_6 !important;
          border-color: $gray_6 !important;
          color: $white !important;
        }
      }
    }
  }

  &__title {
    @include media-breakpoint-down(lg) {
      @include toRem(font-size, 16);
    }
  }
}