//Directory "img"
$icons: "../img/icons/";

//Colors
$black: #000000;
$red: #EE1C26;
$gray_1: #505050;
$gray_2: #888888;
$gray_3: #D7D7D7;
$gray_4: #B2B2B2;
$gray_5: #999999;
$gray_6: #333333;
$gray_7: #F2F2F2;
$white: #fff;

$theme-colors: (
        "red": $red,
        "gray-1": $gray_1,
        "gray-2": $gray_2,
        "gray-3": $gray_3,
        "gray-4": $gray_4,
        "gray-5": $gray_5,
        "gray-6": $gray_6,
        "gray-7": $gray_7,
);