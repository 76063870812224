// Template "Mission"
.template-mission {
  z-index: 1;

  &__bg {
    z-index: -1;
    @include toRem(height, 1005);
  }

  // Intro
  &__intro {
    &-bg-intro {
      z-index: -1;
      @include toRem(height, 789);

      @include media-breakpoint-down(md) {
        @include toRem(height, 200);
        background-color: $gray_7 !important;
      }
    }

    &-deco-image {
      &-1 {
        z-index: -1;
        @include toRem(top, 90);
        @include toRem(left, -250);
        @include toRem(width, 474);
        @include toRem(height, 474);
      }

      &-2 {
        z-index: -2;
        @include toRem(top, 75);
        @include toRem(left, -90);
        @include toRem(width, 333);
        @include toRem(height, 355);
      }
    }

    &-title {
      @include media-breakpoint-down(md) {
        background-color: $gray_6;
        margin-bottom: 20px;
        width: 100%;

        p:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        @include toRem(font-size, 40);
        @include toRem(line-height, 45);

        @include media-breakpoint-down(lg) {
          @include toRem(font-size, 32);
          @include toRem(line-height, 40);
        }
      }
    }

    &-image-1 {
      @include media-breakpoint-down(md) {
        width: 65% !important;
      }
    }
  }

  // Blockquote
  &__blockquote {
    &-quote {
      &-left {
        @include toRem(top, -64);
        @include toRem(left, 40);
      }

      &-right {
        @include toRem(bottom, -60);
        @include toRem(right, 40);
      }
    }
  }
}