
.wpcf7-acceptance {
  .wpcf7-list-item {
    margin: 0;
  }

  label {
    @include toRem(font-size, 16);
    font-weight: 400;
    padding-left: 0;
    align-items: center !important;
    display: flex;
    border: none !important;
    a {
      text-decoration: underline;
    }

    input{
      width: 27px !important;
      position: relative;
      border: 1px solid $red;
      border-radius: 4px;
      @include toRem(width, 18);
      @include toRem(height, 18);
      @include toRem(margin-right, 10);
     // @include toRem(top, 4);
      padding: 0;
      cursor: pointer;
      display: inline-flex;
      flex-shrink: 0;

      &:checked {
        background-image: url($icons + "check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
      }
    }
  }
}
/*
.wpcf7-not-valid-tip {
  color: $red;
  position: absolute;
  bottom: -50px;
}
//Forms
form {
  .row {
    @include media-breakpoint-up(xl) {
      @include toRem(margin-bottom, 30);
    }

    > * {
      @include media-breakpoint-down(xl) {
        @include toRem(margin-bottom, 30);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    > .border-custom {
      > p {
        border-bottom: 1px solid $red;
        margin-bottom: 0;
        @include toRem(padding-bottom, 10);
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    @include toRem(font-size, 18);
    @include toRem(margin-bottom, 15);
    font-weight: 700;

    @include media-breakpoint-up(xl) {
      @include toRem(padding-left, 25);
    }
  }

  input {
    @include toRem(font-size, 18);
    border-radius: 0;
    color: $red;
    outline: 0;

    @include media-breakpoint-up(xl) {
      @include toRem(padding-left, 25);
      @include toRem(padding-right, 25);
    }

    &::placeholder {
      color: $red;
    }
  }

  textarea {
    @include toRem(font-size, 18);
    @include toRem(padding, 25);
    outline: 0;
    color: $red;
    border-radius: 0;
    resize: none;
    width: 100%;
    @include toRem(height, 150);

    @include media-breakpoint-up(xl) {
      @include toRem(padding, 25);
      @include toRem(height, 250);
    }
  }
}

.wpcf7-radio {
  .wpcf7-list-item {
    margin: 0 0 0 1.6rem;

    input[type="radio"] {
      position: relative;
      border: 1px solid $red;
      border-radius: 50%;
      @include toRem(width, 14);
      @include toRem(height, 14);
      @include toRem(margin-right, 2);
      padding: 0;
      cursor: pointer;

      &:checked {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          @include toRem(width, 6);
          @include toRem(height, 6);
          background-color: $red;
        }
      }
    }
  }
}

*/