// Single "post"
.single-post {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  .has-iframe {
    @media screen and (max-width: 992px) {
      iframe {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
      }
    }
  }
}
