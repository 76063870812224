// Header
.header {
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(md) {
    z-index: 0;
  }

  &#header-home {
    @include media-breakpoint-up(md) {
      @include toRem(height, 1110);
    }
  }

  &__bg,
  &__image,
  &__infos-bg {
    z-index: -1;
  }

  &__infos {
    &-item {
      &-icon {
        @include toRem(width, 32);
        @include toRem(height, 32);

        @include media-breakpoint-up(md) {
          @include toRem(width, 60);
          @include toRem(height, 60);
        }

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;

          path, polygon {
            fill: $red;
          }
        }
      }

      &-text {
        @include media-breakpoint-down(md) {
          @include toRem(font-size, 8);
          @include toRem(line-height, 10);
        }
      }
    }
  }
}