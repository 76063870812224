// Footer
.footer {
  &__image > img {
    filter: blur(6px);
  }

  &__nav-3 {
    .icon svg path {
      stroke: $black;
    }
  }

  &__social-medias-item {
    position: relative;

    &:last-child {
      @include toRem(top, 2);
    }

    > a {
      @include toRem(width, 20);
      @include toRem(height, 20);

      @include media-breakpoint-up(mobile) {
        &:hover {
          svg path {
            fill: $red;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        path {
          fill: $black;
          @include transition(fill 600ms);
        }
      }
    }
  }

  .copyright-agency {
    color: $gray_3;
  }
}