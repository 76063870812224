//Model "application"
.model-application {
  $self: &;

  &__image {
    @include toRem(height, 242);
  }

  @include media-breakpoint-up(mobile) {
    &:hover {
      #{ $self }__image > img {
        filter: blur(8px);
      }
    }
  }

  &__label {
    z-index: 1;
  }
}