// Template "Our enterprise"
.page-template-our-enterprise {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  .header__image {
    @include media-breakpoint-down(lg) {
      width: 65% !important;
    }
  }
}

.template-our-enterprise {
  &__bg {
    z-index: -1;
    height: 38%;

    @include media-breakpoint-up(sm) {
      height: 42%;
    }

    @include media-breakpoint-up(md) {
      height: 65%;
    }

    @include media-breakpoint-up(xl) {
      height: 100%;
    }

    @include media-breakpoint-up(xl) {
      @include toRem(height, 780);
    }
  }

  &__who-are-we {
    @include toRem(height, 450);

    @include media-breakpoint-up(lg) {
      @include toRem(height, 895);
    }
  }

  &__video-wrapper-video {
    iframe {
      width: 100%;

      @include media-breakpoint-up(lg) {
        @include toRem(min-height, 500);
      }
    }
  }

  &__timeline {
    &-bg {
      z-index: -1;
    }

    &-title {
      @include media-breakpoint-down(lg) {
        color: $black !important;
      }
    }

    &-images {
      &-item-text {
        @include media-breakpoint-down(lg) {
          color: $black !important;
        }
      }
    }

    &-items-item {
      &-text {
        @include media-breakpoint-down(lg) {
          padding: 0 20px;
        }
      }

      &-line {
        position: absolute;
        top: 55px;
        @include toRem(left, 54);
        @include toRem(width, 1);
        background-color: $red;
        height: inherit;
      }

      &-counter {
        position: relative;
        line-height: normal;
        @include media-breakpoint-down(lg) {
          padding: 0 20px;
          margin-top: 20px;
        }

        @include media-breakpoint-up(lg) {
          @include toRem(width, 150);
        }
      }
    }
  }

  &__our-brands-items-item {
    flex-direction: column;
    justify-content: space-around;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      @include toRem(height, 400);
    }

    @include media-breakpoint-up(lg) {
      @include toRem(height, 310);
    }

    &-image {
      width: 65%;
      height: 65%;
    }

    &-link {
      @include media-breakpoint-down(md) {
        position: relative !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        margin-top: 2rem;
      }
    }
  }

  &__our-management-committee {
    &-bg {
      z-index: -1;
      @include toRem(height, 500);
    }

    &-title {
      @include media-breakpoint-down(lg) {
        color: $black !important;
      }
    }

    &-items-item-image {
      @include media-breakpoint-up(xl) {
        @include toRem(height, 450);
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(height, 535);
      }
    }

    .slider-button {
      @include media-breakpoint-up(mobile) {
        &:hover {
          circle {
            stroke: $red;
            fill: $red;
          }

          path {
            stroke: $white;
          }
        }
      }

      circle {
        stroke: $white;

        @include media-breakpoint-down(lg) {
          stroke: $red;
        }
      }

      path {
        stroke: $gray_6;

        @include media-breakpoint-down(lg) {
          stroke: $red;
        }
      }
    }
  }
}

.js-slider-our-management-committee__slider-nav {
  @include media-breakpoint-down(lg) {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}