// Template "Page"
.page-template-page {
  .header {
    @include media-breakpoint-up(lg) {
      &__bg {
        @include toRem(height, 630);
      }

      &__image {
        @include toRem(height, 480);
      }
    }
  }

  .template-page {
    &__items-item-image {
      @include media-breakpoint-up(lg) {
        @include toRem(height, 480);
      }
    }
  }
}