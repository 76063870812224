// Template "Careers"
.page-template-careers {
  #site-navigations {
    margin-bottom: 0 !important;
  }

  .breadcrumb {
    display: none !important;
  }

  .header__image {
    @include media-breakpoint-down(lg) {
      width: 65% !important;
    }
  }

  .header__bg {
    height: 38%;

    @include media-breakpoint-up(sm) {
      height: 42%;
    }

    @include media-breakpoint-up(md) {
      height: 65%;
    }

    @include media-breakpoint-up(xl) {
      height: 100%;
    }
  }

  .template-careers {
    &__collaborators-bg {
      z-index: -1;
    }

    &__contact-push-image {
      @include media-breakpoint-up(lg) {
        @include toRem(height, 534);
      }
    }

    &__items-item {
      .border-gray-custom {
        @include media-breakpoint-down(lg) {
          border: 0 !important;
        }
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}