//Breadcrumb
.breadcrumb {
  z-index: 0;

  body.home &,
  body.page-template-our-applications &,
  body.tax-type_of_applications & {
    display: none !important;
  }

  &-internal {
    body.home &,
    body.page-template-our-applications &,
    body.tax-type_of_applications & {
      display: block !important;
    }
  }

  ul {
    li {
      position: relative;
      @include toRem(padding-right, 5);
      @include toRem(margin-right, 5);

      &, a {
        @include toRem(font-size, 14);
        color: $gray_2;

        @include media-breakpoint-up(lg) {
          body.page-template-our-enterprise & {
            color: $white;
          }
        }
      }

      &:last-child {
        padding: 0;
        margin: 0;

        &:after {
          content: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        @include toRem(top, 15);
        @include toRem(right, -1);
        @include toRem(width, 2);
        @include toRem(height, 2);
        background-color: $gray_2;
        border-radius: 50%;

        @include media-breakpoint-up(lg) {
          body.page-template-our-enterprise & {
            background-color: $white;
          }
        }
      }
    }
  }
}