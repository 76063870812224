// Template "List"
.page-template-our-applications {
  #site-navigations {
    margin-bottom: 0 !important;
  }
}

.template-list {
  &__filters {
    ul {
      li {
        a {
          @include media-breakpoint-up(mobile) {
            &:hover {
              > .icon {
                opacity: 1;
                @include transform(translateX(0));
              }
            }
          }

          &.active {
            > .icon {
              opacity: 1;
              @include transform(translateX(0));
            }
          }

          > .icon {
            @include toRem(width, 16);
            @include toRem(height, 16);
            opacity: 0;
            @include transform(translateX(-15px));
            @include transition(all 600ms);

            svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    label,
    button {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: flex-start;
      @include transition(color 600ms);

      @include media-breakpoint-up(mobile) {
        &:hover {
          color: $red !important;

          input[type="checkbox"],
          input[type="radio"] {
            background-color: $black;
          }
        }
      }

      input[type="checkbox"],
      input[type="radio"] {
        position: relative;
        border: 1px solid $black;
        @include toRem(top, 6);
        @include toRem(width, 11);
        @include toRem(height, 11);
        @include toRem(margin-right, 10);
        padding: 0;
        cursor: pointer;
        flex-shrink: 0;
        border-radius: 0;

        &:checked {
          background-color: $black;
        }
      }
    }

    &-items-item {
      .template-list__filters-items-item-link {
        cursor: pointer;
        @include transition(all 600ms);
      }

      @include media-breakpoint-up(mobile) {
        &:hover {
          .template-list__filters-items-item-link {
            background-color: $red;
            color: $white !important;
          }
        }
      }

      &.active {
        .template-list__filters-items-item-link {
          background-color: $red;
          color: $white !important;
        }
      }
    }
  }

  &__filter-by-product-button {
    @include media-breakpoint-down(mobile) {
      border: 1px solid $black !important;
      border-radius: 6px;
      padding: 0.8rem 1.875rem !important;
      background-position: center right 30px !important;
    }
  }

  .accordion-item {
    margin-bottom: 10px;
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-collapse {
    @include toRem(padding-top, 10);
  }

  .accordion-button {
    position: relative;
    width: 100%;
    text-align: left;
    background-image: url($icons + "more.svg");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center right;
    display: block;

    &:not(.collapsed) {
      background-image: url($icons + "less.svg");
    }
  }

  // Template "List News"
  &-news {
    &__filters-item {
      @include media-breakpoint-up(mobile) {
        &:hover {
          > a {
            cursor: pointer;
            color: $white !important;
            background-color: $red;
          }
        }
      }

      &.active {
        > a {
          cursor: pointer;
          color: $white !important;
          background-color: $red;
        }
      }

      > a {
        @include transition(all 600ms);

        @include media-breakpoint-down(lg) {
          @include toRem(font-size, 14);
        }
      }
    }
  }
}